.container{
    display : flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.form{
    margin : 20px;
}

.lbl{
    margin-top: 5px;
    padding: 5px;
}

.btn{
    margin: 20px;
    align-items: center;
    padding: 10px;
    font-size: 15px;
}

input{
    margin: 7px;
}