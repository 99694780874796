/* styles.css */

/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

/* Container styles */
.container {
  display: flex;
}

/* Navbar styles */
.navbar {
  width: 100%;
  height: 100px;
  background-color: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-brand {
  font-size: 1.2rem;
  font-weight: bold;
}

.navbar input {
  padding: 10px;
}

.search-button {
  color: #333;
  border: 1px solid;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 20px;
  border-radius: 5px;
}

.search-button:hover {
  background-color: #818181;
}

/* Sidenav styles */
.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 100px;
  left: 0;
  background-color: #cccccc;
  overflow-x: hidden;
  padding-top: 20px;
}

.sidenav a {
  padding: 15px 8px 6px 16px;
  text-decoration: none;
  color: #404040;
  display: block;
}

.sidenav a:hover {
  color: #818181;
}

.sidenav .sidebar-link {
  background-color: #cccccc;
}

.active {
  background-color: #818181;
}
.logo1{
  width: 70px;
  height: 70px;
  margin: auto;
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
}

/* Content styles */
.content {
  position: absolute;
  margin-top: -900px;
  margin-left: 348px;
  padding-top: 60px;
  overflow: auto;
  max-height: calc(100vh - 120px);
  align-items: center;
}

/* Table styles */
th,
td {
  padding: 7px;
  text-align: center;
  border: 2px solid;
}

.table-format {
  border-collapse: collapse;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
