/* src/components/Login.css */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login-wrapper {
    width: 400px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .login-heading {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .login-form .form-group {
    margin-bottom: 15px;
  }
  
  .login-form label {
    display: block;
    font-weight: bold;
  }
  
  .login-form input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-btn {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-btn:hover {
    background-color: #0056b3;
  }
  
  .forgot-password {
    text-align: center;
    margin-top: 10px;
  }
  
  .forgot-password a {
    text-decoration: none;
    color: #007bff;
  }
  
  .forgot-password a:hover {
    color: #0056b3;
  }
  .logo{
    width: 150px;
    height: 150px;
    margin: auto;
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
  }
  